import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@state/app.state';
import { firstValueFrom, timer } from 'rxjs';

import { activeLanguageSelector$ } from './store/selectors/settings.selectors';
import { defaultLangKeySelector$ } from './store/selectors/params.selector';
import { GarminIntegrationService } from './services/helpers/garmin-integration.service';
import { LocalNotificationsService } from './services/helpers/local-notifications.service';
import { pushToSessionListAction } from '@actions/sessions.actions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  $activeLanguage = this.store.select(activeLanguageSelector$);
  $defaultLanguage = this.store.select(defaultLangKeySelector$);

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private _garminSrv: GarminIntegrationService,
    private _localNots: LocalNotificationsService
  ) {
    this.$defaultLanguage.subscribe((defaultLanguage) => {
      translate.setDefaultLang(defaultLanguage);
    });
  }

  async ngOnInit(): Promise<void> {
    const activeLanguage = await firstValueFrom(this.$activeLanguage);
    this.translate.use(activeLanguage);
    this._garminSrv.CheckGarminCBFromNativeRedirects();
    this._localNots.CheckNotificationPermissions();
    this.store.dispatch(pushToSessionListAction());
  }
  ngAfterViewInit(): void {
    this.hideApp();
  }
  private hideApp() {
    // const isTabletOrWeb = !/Android|webOS|iPad|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //     navigator.userAgent
    //   );
    // const isWebUser = /Mozilla|Chrome|Opera|Firefox/.test(navigator.userAgent);

    // Hide/show elements based on the detection result
    const synairgContainer = document.querySelector(
      '.synairg-container'
    ) as HTMLElement;

    const infoContainer = document.querySelector('.info') as HTMLElement;
    if (!synairgContainer || !infoContainer) return;
    // if (isWebUser) {
    if (false) {
      synairgContainer.style.display = 'none';
      infoContainer.style.display = 'block';
    } else {
      synairgContainer.style.display = 'block';
      infoContainer.style.display = 'none';
    }
  }
}
